var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "files" }, [
    _c(
      "li",
      {
        staticClass: "files__item",
        class: { "active--green": _vm.beat.tagged_file_name_thecharts },
      },
      [
        _vm.beat.tagged_file_name_thecharts
          ? _c("icon", {
              staticClass: "files__icon beat-table__files--icon",
              attrs: { name: "check" },
            })
          : _c("icon", {
              staticClass: "files__icon",
              attrs: { name: "cancel" },
            }),
        _vm._v("Tagged"),
      ],
      1
    ),
    _c(
      "li",
      { staticClass: "files__item", class: { active: _vm.beat.mp3_file_name } },
      [
        _vm.beat.mp3_file_name
          ? _c("icon", { staticClass: "files__icon", attrs: { name: "check" } })
          : _c("icon", {
              staticClass: "files__icon",
              attrs: { name: "cancel" },
            }),
        _vm._v("MP3"),
      ],
      1
    ),
    _c(
      "li",
      { staticClass: "files__item", class: { active: _vm.beat.wav_file_name } },
      [
        _vm.beat.wav_file_name
          ? _c("icon", { staticClass: "files__icon", attrs: { name: "check" } })
          : _c("icon", {
              staticClass: "files__icon",
              attrs: { name: "cancel" },
            }),
        _vm._v("WAV"),
      ],
      1
    ),
    _c(
      "li",
      {
        staticClass: "files__item",
        class: {
          active: _vm.beat.trackout_file_name || _vm.beat.trackout_external_url,
        },
      },
      [
        _vm.beat.trackout_file_name || _vm.beat.trackout_external_url
          ? _c("icon", { staticClass: "files__icon", attrs: { name: "check" } })
          : _c("icon", {
              staticClass: "files__icon",
              attrs: { name: "cancel" },
            }),
        _vm._v("Trackout"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }