var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-producer" },
    [
      _c("header", { staticClass: "header header--borderBottom" }, [
        _c("div", { staticClass: "container headerContainer" }, [
          _vm._m(0),
          _c("div", { staticClass: "headerContainer__alignRight" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn--sm btn--black btn--text btn--withIconLeft",
                on: {
                  click: function ($event) {
                    return _vm.$router.back()
                  },
                },
              },
              [
                _c("icon", {
                  attrs: { name: "arrow-left", width: "14", height: "14" },
                }),
                _vm._v("GO BACK"),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              var invalid = ref.invalid
              return [
                _c("section", { staticClass: "section" }, [
                  _vm.isLoading && !_vm.beat
                    ? _c(
                        "div",
                        { staticClass: "container container--fullWidth" },
                        [_c("VLoadSpinner")],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "container container--fullWidth" },
                        [
                          _c(
                            "div",
                            { staticClass: "grid grid__col-small-big" },
                            [
                              _c(
                                "div",
                                { staticClass: "flexbox flexbox--center" },
                                [
                                  _c("div", [
                                    _c("div", { staticClass: "formGroup" }, [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "form__title h-textCenter",
                                        },
                                        [_vm._v("Beat Image")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "formUpload formUpload--square",
                                          class: {
                                            "formUpload--empty":
                                              !_vm.beat.image_url,
                                            "formUpload--uploaded":
                                              _vm.beat.image_url,
                                          },
                                        },
                                        [
                                          _c("icon", {
                                            staticClass: "formUpload__icon",
                                            attrs: { name: "camera" },
                                          }),
                                          _vm.beat.image_url
                                            ? _c("img", {
                                                staticClass:
                                                  "formUpload__image",
                                                attrs: {
                                                  src: _vm.beat.image_url,
                                                },
                                              })
                                            : _vm._e(),
                                          _c("input", {
                                            staticClass:
                                              "formUpload__inputHide",
                                            attrs: { type: "file" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.uploadBeatImage(
                                                  $event.target.files
                                                )
                                              },
                                            },
                                          }),
                                          _vm.beat.image_url
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn--danger btn--sm formUpload__btnDelete",
                                                  on: {
                                                    click: _vm.removeBeatImage,
                                                  },
                                                },
                                                [_vm._v("remove")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flexbox flexbox--center h-textCenter h-mt-30",
                                      },
                                      [
                                        _c("icon", {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: { name: "play", width: "16" },
                                        }),
                                        _c("p", [
                                          _vm._v(
                                            "Beat Plays: " +
                                              _vm._s(_vm.beatPlays)
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "wrapper--filled wrapper--paddingLg grid grid__col2",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "formGroup formGroup--horizontal",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "flex75" },
                                            [
                                              _c("ValidationProvider", {
                                                attrs: {
                                                  name: "Name",
                                                  rules: { required: true },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "formLabel",
                                                            },
                                                            [_vm._v("Name")]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.beat.name,
                                                                expression:
                                                                  "beat.name",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "formInput formInput--fullWidth",
                                                            class: {
                                                              hasError:
                                                                errors.length >
                                                                0,
                                                            },
                                                            attrs: {
                                                              name: "name",
                                                              type: "text",
                                                              placeholder:
                                                                "Enter beat name",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.beat.name,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.beat,
                                                                  "name",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex25" },
                                            [
                                              _c("ValidationProvider", {
                                                attrs: {
                                                  name: "BPM",
                                                  rules: {
                                                    required: true,
                                                    numeric: true,
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "formLabel",
                                                            },
                                                            [_vm._v("BPM")]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model.number",
                                                                value:
                                                                  _vm.beat.bpm,
                                                                expression:
                                                                  "beat.bpm",
                                                                modifiers: {
                                                                  number: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "formInput formInput--fullWidth",
                                                            class: {
                                                              hasError:
                                                                errors.length >
                                                                0,
                                                            },
                                                            attrs: {
                                                              name: "bpm",
                                                              type: "text",
                                                              placeholder: "90",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.beat.bpm,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.beat,
                                                                  "bpm",
                                                                  _vm._n(
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                )
                                                              },
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.$forceUpdate()
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c("BeatInfoDropdown", {
                                        attrs: {
                                          "selected-artist-type":
                                            _vm.beat.artist_type,
                                          "selected-genre": _vm.beat.genre,
                                          selectedMainInstrument:
                                            _vm.beat.main_instrument,
                                        },
                                        on: {
                                          setArtistType: _vm.setArtistType,
                                          setGenre: _vm.setGenre,
                                          setMainInstrument:
                                            _vm.setMainInstrument,
                                        },
                                      }),
                                      _c("div", { staticClass: "formGroup" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.beat.sampled,
                                              expression: "beat.sampled",
                                            },
                                          ],
                                          staticClass: "formCheckbox",
                                          class: { checked: _vm.beat.sampled },
                                          attrs: {
                                            id: "sampled",
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.beat.sampled
                                            )
                                              ? _vm._i(_vm.beat.sampled, null) >
                                                -1
                                              : _vm.beat.sampled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.beat.sampled,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.beat,
                                                      "sampled",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.beat,
                                                      "sampled",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.beat,
                                                  "sampled",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "sampled" } },
                                          [_vm._v("Contains Samples")]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "formGroup formGroup--horizontal h-alignCenter",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "formLabel formLabel--dark",
                                            },
                                            [_vm._v("INCLUDE IN VAULT BEATS")]
                                          ),
                                          _c("toggle-button", {
                                            staticClass:
                                              "vue-js-switch--margin",
                                            attrs: {
                                              color: "#0269FF",
                                              labels: true,
                                            },
                                            model: {
                                              value: _vm.beat.is_private_beat,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.beat,
                                                  "is_private_beat",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "beat.is_private_beat",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("hr", {
                                        staticClass: "form__divider",
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "formGroup formGroup--horizontal h-alignCenter",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "formLabel formLabel--dark",
                                            },
                                            [_vm._v("TAGGED DL ALLOWED")]
                                          ),
                                          _c("toggle-button", {
                                            staticClass:
                                              "vue-js-switch--margin",
                                            attrs: {
                                              color: "#0269FF",
                                              labels: true,
                                            },
                                            model: {
                                              value:
                                                _vm.beat.demo_download_allowed,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.beat,
                                                  "demo_download_allowed",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "beat.demo_download_allowed",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("hr", {
                                        staticClass: "form__divider",
                                      }),
                                      _c("beat-uploaded-files", {
                                        staticClass: "formGroup",
                                        attrs: { beat: _vm.beat },
                                      }),
                                      _c("hr", {
                                        staticClass: "form__divider",
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "URL",
                                              rules: { regex: _vm.regexUrl },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "formLabel",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Trackout Link"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.beat
                                                                .trackout_external_url,
                                                            expression:
                                                              "beat.trackout_external_url",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "url",
                                                          type: "text",
                                                          placeholder:
                                                            "https://www....",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.beat
                                                              .trackout_external_url,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.beat,
                                                              "trackout_external_url",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "p--grey textSizeXs h-textJustify",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "If you host your trackout files yourself put the link here, otherwise you can upload your trackouts directly to TheCharts. "
                                                          ),
                                                          _c(
                                                            "router-link",
                                                            {
                                                              staticClass:
                                                                "link",
                                                              attrs: {
                                                                to: {
                                                                  name: "BeatsUpload",
                                                                  query: {
                                                                    type: _vm
                                                                      .UploadType
                                                                      .NewBeat,
                                                                  },
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Upload here."
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.hasExclusiveLicense
                                ? [
                                    _c("div"),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "wrapper--filled wrapper--paddingLg",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "formGroup" },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "Exclusive Price",
                                                rules: { numeric: true },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flexbox",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "noWrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Exclusive Price $ "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "textSizeXs",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "(USD)"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.beat
                                                                      .price_for_exclusive,
                                                                  expression:
                                                                    "beat.price_for_exclusive",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "formInput formInput--fullWidth",
                                                              class: {
                                                                hasError:
                                                                  errors.length >
                                                                  0,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                name: "exclusive price",
                                                                placeholder:
                                                                  "$",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.beat
                                                                    .price_for_exclusive,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.beat,
                                                                      "price_for_exclusive",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "formInput__hint formInput__hint--error",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "formGroup" },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "textSizeXs" },
                                              [
                                                _vm._v(
                                                  "Note: You may customize your licenses here: "
                                                ),
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "link",
                                                    attrs: {
                                                      to: { name: "Licenses" },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Licenses & Contracts"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _c("div"),
                              _vm.coproducerData
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "click-outside",
                                          rawName: "v-click-outside",
                                          value: _vm.closeDropdowns,
                                          expression: "closeDropdowns",
                                        },
                                      ],
                                      staticClass:
                                        "wrapper--filled wrapper--paddingLg",
                                    },
                                    [
                                      _c("h4", { staticClass: "form__title" }, [
                                        _vm._v("Co-producers"),
                                      ]),
                                      _vm._l(
                                        _vm.coproducerData,
                                        function (data, index) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass:
                                                "formGroup formGroup--horizontal",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex60 dropdown",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "formLabel",
                                                    },
                                                    [_vm._v("Co-producer")]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "formInput formInput--fullWidth formInput--selectCustom",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.coproducersDropdown =
                                                            index
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          data &&
                                                            data.coproducer
                                                            ? data.coproducer
                                                            : "Select Producer"
                                                        )
                                                      ),
                                                      _c("icon", {
                                                        attrs: {
                                                          name: "arrow-down",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.coproducersDropdown ===
                                                  index
                                                    ? _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "dropdown__menu",
                                                        },
                                                        _vm._l(
                                                          _vm.coproducerEmails,
                                                          function (email) {
                                                            return _c(
                                                              "li",
                                                              {
                                                                key: email,
                                                                staticClass:
                                                                  "dropdown__item dropdown__item--sm",
                                                                class: {
                                                                  isActive:
                                                                    email ===
                                                                    data.coproducer,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setCoproducerData(
                                                                        {
                                                                          atIndex:
                                                                            index,
                                                                          email:
                                                                            email,
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(email)
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex30 dropdown",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "formLabel",
                                                    },
                                                    [_vm._v("Com. %")]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "formInput formInput--fullWidth formInput--selectCustom",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.commissionPercentageDropdown =
                                                            index
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          data &&
                                                            data.commission
                                                            ? data.commission
                                                            : "0"
                                                        ) + "%"
                                                      ),
                                                      _c("icon", {
                                                        attrs: {
                                                          name: "arrow-down",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.commissionPercentageDropdown ===
                                                  index
                                                    ? _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "dropdown__menu",
                                                        },
                                                        _vm._l(
                                                          100,
                                                          function (n) {
                                                            return _c(
                                                              "li",
                                                              {
                                                                key: n,
                                                                staticClass:
                                                                  "dropdown__item dropdown__item--sm",
                                                                class: {
                                                                  isActive:
                                                                    n ===
                                                                    data.commission,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setCoproducerData(
                                                                        {
                                                                          atIndex:
                                                                            index,
                                                                          commission:
                                                                            n,
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(n) +
                                                                    "%"
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c("icon", {
                                                staticClass: "h-mt-30",
                                                attrs: { name: "trash" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.deleteCoproducerAtIndex(
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form__submit" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn--text h-textCenter",
                                              staticStyle: { display: "block" },
                                              on: {
                                                click: _vm.addNewCoproducer,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Select Another Co-Producer"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                ]),
                _c("section", { staticClass: "section__bottom-button" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--primary",
                      class: { isDisabled: invalid },
                      on: {
                        click: function ($event) {
                          return handleSubmit(_vm.saveAllChanges)
                        },
                      },
                    },
                    [_vm._v("SAVE CHANGES")]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerContainer__heading" }, [
      _c("h3", [_vm._v("Beats")]),
      _c("h1", [_vm._v("Edit Beat")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }